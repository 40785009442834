import {createSelector} from '@ngrx/store';
import {AppState} from '../states/app.state';
import {ProduktEntitiesSelectors} from './produkt-entities.selectors';
import {FakturierungsbelegFormDto} from '../../interfaces/fakturierungsbeleg-form-dto.interface';
import {identity} from 'rxjs';


export class FakturierungsbelegFormSelectors {
  /**
   * Die `select`-Eigenschaft ist ein Objekt, das Funktionen definiert, jede Funktion selektiert eine
   * bestimmte Eigenschaft aus dem State.
   * Das sind grundlegende Selektoren, die im ganzen Projekt als Bausteine für komplexere Selektoren wiederverwendet
   * werden können.
   */
  private static select = {
    isFormValid: (state: AppState) => state.fakturierungsbelegForm.isFormValid,
    fakturierungsbelegFormDto: (state: AppState) => state.fakturierungsbelegForm.fakturierungsbelegFormDto,
    logoUrl: (state: AppState) => state.fakturierungsbelegForm.logoUrl,
    leistungsempfaengerMode: (state: AppState) => state.fakturierungsbelegForm.leistungsempfaengerMode,
    vorlaufzeileMode: (state: AppState) => state.fakturierungsbelegForm.vorlaufzeileMode,
    positionAnzahlModes: (state: AppState) => state.fakturierungsbelegForm.positionAnzahlModes,
    positionBetragssummeModes: (state: AppState) => state.fakturierungsbelegForm.positionBetragssummeModes,
    addProduktMode: (state: AppState) => state.fakturierungsbelegForm.addProduktMode,
    rechnungsdatumMode: (state: AppState) => state.fakturierungsbelegForm.rechnungsdatumMode,
    leistungsLieferdatumControlMode: (state: AppState) => state.fakturierungsbelegForm.leistungsLieferdatumControlMode,
    leistungsLieferdatumDateMode: (state: AppState) => state.fakturierungsbelegForm.leistungsLieferdatumDateMode,
    nachlaufzeilen: (state: AppState) => state.fakturierungsbelegForm.nachlaufzeilen,
  };

  /**
   * Gibt das FakturierungsbelegFormDto zurück.
   * Der Fakturierungsbeleg enthält auch die zugehörigen Positionen und Produkte.
   * Diese werden direkt aus den ProduktEntities abgeleitet.
   */
  public static fakturierungsbelegFormDto = createSelector(
    FakturierungsbelegFormSelectors.select.fakturierungsbelegFormDto,
    ProduktEntitiesSelectors.all,
    (fakturierungsbelegFormDto, produktDtos) => {
      if (!fakturierungsbelegFormDto) return undefined;

      const clonedDto: FakturierungsbelegFormDto = {...fakturierungsbelegFormDto};

      // INFO: Hier werden die Produkte zu den Positionen geladen und dem FakturierungsbelegFormDtos hinzugefügt.
      for (const position of clonedDto.positionen || []) {
        const produktDto = produktDtos.find(produktDto => produktDto.id === position.id);
        if (produktDto) {
          clonedDto.produkteZuPositionen = [
            ...clonedDto.produkteZuPositionen || [],
            produktDto,
          ];
        }
      }

      return clonedDto;
    },
  );

  /**
   * Selektiert den isFormValid.
   */
  public static isFormValid = createSelector(
    FakturierungsbelegFormSelectors.select.isFormValid,
    identity,
  );

  /**
   * Selektiert die AbsenderId des Fakturierungsbelegs.
   */
  public static kundeId = createSelector(
    FakturierungsbelegFormSelectors.select.fakturierungsbelegFormDto,
    fakturierungsbelegFormDto => {
      return fakturierungsbelegFormDto?.kundeId || undefined;
    }
  );

  /**
   * Selektiert die Positionen des Fakturierungsbelegs.
   */
  public static positionen = createSelector(
    FakturierungsbelegFormSelectors.select.fakturierungsbelegFormDto,
    fakturierungsbelegFormDto => {
      return fakturierungsbelegFormDto?.produkteZuPositionen || [];
    }
  );

  /**
   * Selektiert den LeistungsempfaengerMode.
   */
  public static leistungsempfaengerMode = createSelector(
    FakturierungsbelegFormSelectors.select.leistungsempfaengerMode,
    identity,
  );

  /**
   * Selektiert den VorlaufzeileMode.
   */
  public static vorlaufzeileMode = createSelector(
    FakturierungsbelegFormSelectors.select.vorlaufzeileMode,
    identity,
  );

  /**
   * Selektiert den Text der Vorlaufzeile des Fakturierungsbelegs.
   */
  public static vorlaufzeileText = createSelector(
    FakturierungsbelegFormSelectors.select.fakturierungsbelegFormDto,
    fakturierungsbelegFormDto => {
      return fakturierungsbelegFormDto?.vorlaufzeile;
    }
  );

  /**
   * Selektiert das Logo des Inhabers.
   */
  public static logoUrl = createSelector(
    this.select.logoUrl,
    identity,
  );

  /**
   * Selektiert die Position-Anzahl-Modi der Positionen.
   */
  public static positionAnzahlModes = createSelector(
    FakturierungsbelegFormSelectors.select.positionAnzahlModes,
    identity,
  );

  /**
   * Selektiert die Betragssumme-Modi der Positionen.
   */
  public static positionBetragssummeModes = createSelector(
    FakturierungsbelegFormSelectors.select.positionBetragssummeModes,
    identity,
  );

  /**
   * Selektiert den Modus der Produkt-Hinzufügen-Funktion.
   */
  public static addProduktMode = createSelector(
    FakturierungsbelegFormSelectors.select.addProduktMode,
    identity,
  );

  /**
   * Selektiert den Modus des Rechnungsdatums.
   */
  public static rechnungsdatumMode = createSelector(
    FakturierungsbelegFormSelectors.select.rechnungsdatumMode,
    identity,
  );

  /**
   * Selektiert den Modus des Controls des Leistungs- und Lieferdatum.
   */
  public static leistungsLieferdatumControlMode = createSelector(
    FakturierungsbelegFormSelectors.select.leistungsLieferdatumControlMode,
    identity,
  );

  /**
   * Selektiert den Modus des Leistungs- und Lieferdatum.
   */
  public static leistungsLieferdatumDateMode = createSelector(
    FakturierungsbelegFormSelectors.select.leistungsLieferdatumDateMode,
    identity,
  );

  /**
   * Selektiert das Rechnungsdatum.
   */
  public static rechnungsdatum = createSelector(
    FakturierungsbelegFormSelectors.select.fakturierungsbelegFormDto,
    fakturierungsbelegFormDto => {
      return fakturierungsbelegFormDto?.datum;
    }
  );

  /**
   * Selektiert den Text der Nachlaufzeile.
   */
  public static nachlaufzeilen = createSelector(
    FakturierungsbelegFormSelectors.select.nachlaufzeilen,
    identity,
  );
}
